.papervenue {
    font-size: x-small;
}

.papertitle {
    font-size: large;
}

.titlebox {
    height: 8em;
    display: flex;
    align-items: flex-end;
}